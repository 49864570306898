import { shangjiaServerInstance } from "../axios/instances";
import { FLEET_SETTLEMENT as types } from "./types"

/**
 * 
 * @param {string} period_start 
 * @param {string} period_end 
 * @returns {{
 *  data: Array<{
 *    warehouse_id: number
 *    airport_code: string
 *    adj_amount: number
 *  }>
 * }}
 */
export function getDriverPaymentAdjustments(period_start, period_end) {
  return function (dispatch) {
    dispatch({ type: types.GET_DRIVER_SUBSIDY_TOTAL })
    return shangjiaServerInstance.get(`/driver/paymentadjustments?period_start=${period_start}&period_end=${period_end}`)
      .then(({ data }) => {
        dispatch({
          type: types.GET_DRIVER_SUBSIDY_TOTAL_SUCCESS,
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type: types.GET_DRIVER_SUBSIDY_TOTAL_FAIL,
        })
        return Promise.reject(error);
      })
  }
}

/**
 * 
 * @param {string} period_start
 * @param {string} period_end
 * @param {file} file 
 * @returns {{
 *  file_id: string
 *  data: Array<{
 *    warehouse_id: number
 *    airport_code: string
 *    adj_amount: number
 *  }>
 *  warnings: Array<string>
 *  errors: Array<string>
 * }}
 */
export function addDriverPaymentAdjustments(period_start, period_end, file) {
  return function (dispatch) {
    dispatch({ type: types.UPLOAD_DRIVER_SUBSIDY_FILE })
    const formData = new FormData()
    formData.append('period_start', period_start)
    formData.append('period_end', period_end)
    formData.append('file', file)
    return shangjiaServerInstance.post("/driver/paymentadjustments", formData)
      .then(({ data }) => {
        dispatch({
          type: types.UPLOAD_DRIVER_SUBSIDY_FILE_SUCCESS,
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type: types.UPLOAD_DRIVER_SUBSIDY_FILE_FAIL,
        })
        return Promise.reject(error);
      })
  }
}

/**
 * 
 * @param {string} file_id 
 * @returns 
 */
export function deleteDriverPaymentAdjustments(file_id) {
  return function (dispatch) {
    dispatch({ type: types.DELETE_DRIVER_SUBSIDY_FILE })
    return shangjiaServerInstance.delete(`/driver/paymentadjustments?file_id=${file_id}`)
      .then(() => {
        dispatch({
          type: types.DELETE_DRIVER_SUBSIDY_FILE_SUCCESS,
        })
        return Promise.resolve();
      }).catch(error => {
        dispatch({
          type: types.DELETE_DRIVER_SUBSIDY_FILE_FAIL,
        })
        return Promise.reject(error);
      })
  }
}