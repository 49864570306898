import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography
} from '@material-ui/core';
import React, {useEffect, useState, useCallback} from 'react';
import {showAlertSnackbar} from "../../../reducers/uiSlice";
import {useAlert} from "../../../utilities/hooks";
import {DataGrid} from "@material-ui/data-grid";
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import {getPartnerCapacities, getWarehouseCapacities} from "../../../actions/deliveryCapacityActions";

const useStyle = makeStyles((theme) => ({
  header: {
    marginLeft: 30,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  button: {
    backgroundColor: "#1976d2",
    color: " #fff",
    borderRadius: 4,
    padding: "15px 20px",
    fontSize: "12px",
    marginLeft: theme.spacing(2),
    maxHeight: "36px",
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)"
    }
  },
  footerContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: theme.spacing(6),
    paddingRight: theme.spacing(2),
  },
  tableArea: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    height: '90%'
  },
  table: {
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 12,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.28,
      textAlign: 'left',
      color: 'rgba(185, 185, 185, 0.87)'
    },
    '& .MuiDataGrid-columnHeader': {
      color: '#000000',
    },
    '& .MuiDataGrid-row': {
      height: 56,
      textAlign: 'left',
    },
    '& .green-text': {
      color: 'green'
    },
    '& .yellow-text': {
      color: 'orange'
    },
    '& .red-text': {
      color: 'red'
    }
  },
}));

const ALERT = {
  SEARCH_SUCCESS  : "FETCH DATA SUCCESS",
  SEARCH_FAILED   : "FETCH DATA FAILED"
};

export const top4PartnerIds = [57, 83, 85, 54] // Shein, Temu, TikTok, Cainiao

function DeliveryCapacityDisplay(props) {
  const {displayOpen} = props;

  const dispatch = useDispatch();
  const classes = useStyle();
  const alertDialog = useAlert();
  const [loading, setLoading] = useState(false);

  const [rows, setRows] = useState([]);
  const [partnerRows, setPartnerRows] = useState([]);
  const [sparedCapacity, setSparedCapacity] = useState(null);
  const [year, setYear] = useState('');
  const [week, setWeek] = useState(0);
  const [warehouse, setWarehouse] = useState('');
  const [openView, setOpenView] = useState(false);

  const localizedNumberFormatter = new Intl.NumberFormat('en-US');

  const columns = [
    {
      field: 'warehouse',
      headerName: 'Warehouse',
      flex: 1
    },
    {
      field: 'capacity',
      headerName: 'Capacity',
      flex: 1,
      valueFormatter: (params) => localizedNumberFormatter.format(params.value),
    },
    {
      field: 'counts',
      headerName: 'Received Volume',
      flex: 1,
      valueFormatter: (params) => localizedNumberFormatter.format(params.value),
    },
    {
      field: 'rate',
      headerName: 'Inbound Rate',
      flex: 1,
      cellClassName: (params) => getRateCellClassName(params, 'rate'),
      valueFormatter: (params) => (params.value + '%'),
    },
    {
      field: 'status',
      headerName: 'Inbound Status',
      flex: 1,
      cellClassName: (params) => getRateCellClassName(params, 'rate'),
    }
  ];

  const partnerCol = [
    {
      field: 'name',
      headerName: 'Partner Name',
      width: 200
    },
    {
      field: 'capacity',
      headerName: 'Capacity',
      flex: 1,
      valueFormatter: (params) => localizedNumberFormatter.format(params.value),
    },
    {
      field: 'counts',
      headerName: 'Received Volume',
      flex: 1,
      cellClassName: (params) => getRateCellClassName(params, 'rate'),
    },
    {
      field: 'orders',
      headerName: 'Planned Rate',
      flex: 1,
      valueFormatter: (params) => (params.value + '%'),
    },
    {
      field: 'rate',
      headerName: 'Actual Rate',
      flex: 1,
      cellClassName: (params) => getRateCellClassName(params, 'rate'),
      valueFormatter: (params) => (params.value + '%'),
    }
  ];

  const getRateCellClassName = (params, field) => {
    const rate = parseFloat(params.row[field]);
    if (rate >= 100) {
      return 'red-text';
    } else if (rate >= 80) {
      return 'yellow-text';
    }
    return 'green-text';
  };

  const handleSearch = useCallback(async () => {
    setLoading(true);
    try {
      const res = await dispatch(getWarehouseCapacities());
      const updatedRows = res.map((row) => ({
        id: row.warehouse_id,
        warehouse: row.name,
        capacity: row.capacity,
        counts: row.current_total,
        rate: row.rate,
        status: row.status ? 'Open' : 'Close',
      }));
      setRows(updatedRows);
      dispatch(showAlertSnackbar({message: ALERT.SEARCH_SUCCESS, type: 'success'}));
    } catch (e) {
      dispatch(showAlertSnackbar({message: ALERT.SEARCH_FAILED, type: 'error'}));
    } finally {
      setLoading(false);
    }
  }, [dispatch])

  const handleRowClick = async (selectedRow) => {
    setLoading(true);
    try {
      setWarehouse(selectedRow.row['warehouse']);
      const res = await dispatch(getPartnerCapacities(selectedRow.id));
      
      const rows = res.map((row) => ({
        id: row.id,
        name: row.name,
        capacity: row.capacity,
        counts: row.count,
        orders: row.threshold_percentage,
        rate: parseFloat(row.percentage),
      }));

      // Put top 4 partners in the beginning
      for (let partnerId of top4PartnerIds.toReversed()) {
        const index = rows.findIndex(row => row.id === partnerId)
        if (index !== -1) {
          const row = rows.splice(index, 1)[0]
          rows.unshift(row)
        }
      }

      // Extract spared capacity out
      const rowIndex = rows.findIndex(row => row.name === 'Spared Capacity')
      if (rowIndex !== -1) {
        const sparedCapacity = rows[rowIndex].capacity
        rows.splice(rowIndex, 1)
        setSparedCapacity(sparedCapacity)
      }
      else {
        setSparedCapacity(null)
      }

      setPartnerRows(rows);
      setOpenView(true);
    } catch (e) {
      alertDialog.addAlertDialog(ALERT.CREATE_FAILED, e.message);
      alertDialog.showAlert();
    } finally {
      setLoading(false);
    }
  }

  const RefreshFooter = () => (
    <div className={classes.footerContainer}>
      <Button
        className={classes.button}
        id={'refresh'}
        style={{cursor: 'pointer'}}
        onClick={handleSearch}
      >
        Refresh
      </Button>
    </div>
  );

  const SparedCapacityFooter = () => (
    <Box height='52px' display='flex' alignItems='center'>
      <Typography variant='body2' color='textSecondary' style={{ width: '200px', padding: '0 10px' }}>Spared</Typography>
      <Typography variant='body2' color='textSecondary' style={{ paddingLeft: '10px' }}>{sparedCapacity.toLocaleString()}</Typography>
    </Box>
  )

  useEffect(() => {
    if (displayOpen) {
      handleSearch();

      dayjs.extend(isoWeek);
      const date = dayjs();
      setYear(date.format('YYYY'));
      setWeek(date.isoWeek());
    }
  }, [displayOpen, handleSearch])

  return (
    <div>
      <div className={classes.header}>
        <p style={{ color: "#577699", fontSize: "18px", fontWeight: 600, margin: "2px 0 1px" }}>Delivery Capacity</p>
      </div>

      <div className={classes.tableArea}>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              color: "#577699",
              fontSize: 18,
              fontWeight: 600,
              marginLeft: 18,
              marginTop: 15,
              marginBottom: 10
            }}
          >
            Week: {week}, {year}
          </div>
        </div>

        <DataGrid
          autoHeight
          className={classes.table}
          rows={rows}
          columns={columns}
          components={{
            Footer: RefreshFooter,
          }}
          loading={loading}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          onRowClick={handleRowClick}
        />
      </div>

      <Dialog maxWidth='xl' open={openView} onClose={() => setOpenView(false)}>
        <DialogTitle style={{color: "#577699", fontWeight: 600}}>{warehouse}</DialogTitle>
        <DialogContent style={{ minWidth: 1100, maxWidth: 1800, padding: '0 24px 24px 24px' }}>
          <DataGrid
            autoHeight
            className={classes.table}
            rows={partnerRows}
            columns={partnerCol}
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick
            components={{
              Footer: sparedCapacity !== null ? SparedCapacityFooter : null,
            }}
            pagination={false}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DeliveryCapacityDisplay;