import { useState, useEffect, useMemo, useCallback } from 'react';
import { Grid, Card, Box, Alert, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import { getDriverPaymentAdjustments, addDriverPaymentAdjustments, deleteDriverPaymentAdjustments } from '../../actions/fleetSettlementActions';
import { showAlertSnackbar } from '../../reducers/uiSlice';
import DragDropFileUpload from '../DragDropFileUpload';
import DocumentDetailDisplay from '../DocumentDetailDisplay';
import FullscreenLoading from '../FullscreenLoading'
import DownloadButton from '../Common/DownloadButton';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

function PaymentAdjustments() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [warnings, setWarnings] = useState([])
  const [errors, setErrors] = useState([])
  const [rows, setRows] = useState([])
  const [file, setFile] = useState(null)
  const [fileId, setFileId] = useState('')
  const [fileRows, setFileRows] = useState([])

  const paymentPeriod = useMemo(() => {
    let start = dayjs()
    let end = dayjs()
    if (process.env.REACT_APP_COUNTRY === 'US') {
      // US payment period is weekly
      start = start.isoWeekday(1) // monday
      end = end.isoWeekday(7) // sunday
    }
    else {
      // CA payment period is semi-monthly
      if (dayjs().date() <= 15) {
        start = start.date(1)
        end = end.date(15)
      }
      else {
        start = start.date(16)
        end = end.endOf('month')
      }
    }
    
    return {
      startValue: start.format('YYYYMMDD'),
      startDate: start.format('YYYY/MM/DD'),
      endValue: end.format('YYYYMMDD'),
      endDate: end.format('YYYY/MM/DD')
    }
  }, [])

  const fetchTotal = useCallback(async () => {
    try {
      setLoading(true)
      const res = await dispatch(getDriverPaymentAdjustments(paymentPeriod.startValue, paymentPeriod.endValue))
      setRows(res.map((row, index) => ({ ...row, id: index })))
    }
    catch (e) {
      dispatch(showAlertSnackbar({ message: 'Unable to fetch data', type: 'error' }));
    }
    finally {
      setLoading(false)
    }
  }, [dispatch, paymentPeriod.startValue, paymentPeriod.endValue])

  useEffect(() => fetchTotal(), [fetchTotal])

  async function upload(file) {
    try {
      setFile(file)
      setLoading(true)
      const res = await dispatch(addDriverPaymentAdjustments(paymentPeriod.startValue, paymentPeriod.endValue, file))
      if (res.errors.length > 0) {
        setErrors(res.errors)
      }
      else {
        if (res.warnings.length > 0) {
          setWarnings(res.warnings)
        }
        setFileId(res.file_id)
        setFileRows(res.data.map((row, index) => ({ ...row, id: index })))
      }
    }
    catch (e) {
      dispatch(showAlertSnackbar({ message: `Unable to upload (${e.message})`, type: 'error' }));
    }
    finally {
      setLoading(false)
    }
  }

  function close() {
    setWarnings([])
    setErrors([])
    setFile(null)
    setFileId('')
    setFileRows([])
  }

  async function undo() {
    try {
      setLoading(true)
      await dispatch(deleteDriverPaymentAdjustments(fileId))
    }
    catch (e) {
      dispatch(showAlertSnackbar({ message: `Unable to undo (${e.message})`, type: 'error' }));
    }
    finally {
      close()
      setLoading(false)
    }
  }

  function done() {
    close()
    fetchTotal()
  }

  const columns = [
    {
      field: 'id',
      hide: true
    },
    {
      field: 'warehouse_id',
      headerName: 'Warehouse ID',
      flex: 1
    },
    {
      field: 'airport_code',
      headerName: 'Airport Code',
      flex: 1
    },
    {
      field: 'adj_amount',
      headerName: 'Adj. Amount',
      flex: 1
    },
  ];

  return (
    <div>
      <Typography variant='subtitle1' sx={{ color: "#577699", fontSize: "18px", fontWeight: "600", marginBottom: 4 }}>
        Payment Adjustments
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Card sx={{ padding: 4 }}>
            <Typography variant='h4' marginBottom={3}>
              Upload Adjustments File
            </Typography>
            <Typography marginBottom={3}>
              Payment Period:
              <Typography component='span' color='text.secondary' marginLeft={1}>
                {paymentPeriod.startDate} - {paymentPeriod.endDate}
              </Typography>
            </Typography>
            <Typography marginBottom={3}>
              Please use the provided template to add payment adjustments.
              <br />
              <DownloadButton onClick={() => window.open('/documents/driver_payment_adjustments_template.csv', '_blank')}>
                template.csv
              </DownloadButton>
            </Typography>
            <DragDropFileUpload
              fileType={'.csv'}
              file={file}
              onFileUpload={setFile}
            />
            <Button
              variant='contained'
              disabled={!file}
              onClick={() => upload(file)}
              sx={{ marginTop: 6 }}>
              Upload
            </Button>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card variant='outlined'>
            <Box padding={2}>
              <Typography variant='h6'>
                Total Payment Adjustments
              </Typography>
            </Box>
            <DataGrid
              rows={rows}
              columns={columns}
              loading={loading}
              getRowId={row => row.id}
              autoHeight
              disableColumnFilter
              disableColumnMenu
              disableSelectionOnClick
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#55A6FF1A'
                }
              }}
            />
          </Card>
        </Grid>
      </Grid>

      <Dialog open={errors.length > 0} fullWidth>
        <DialogTitle>Upload Failed</DialogTitle>
        <DialogContent dividers>
          <Alert severity='error' sx={{ marginBottom: 2 }}>Some parcels have multiple penalties. Please correct the rows below and re-upload the file.</Alert>
          {file && <DocumentDetailDisplay file={file} state={'Failed'} />}
          <Typography component='ul' color='error' marginTop={2}>
            {errors.map((error, index) => {
              return (
                <li key={index}>{error}</li>
              )
            })}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button onClick={close} variant='contained'>
            Close and re-upload
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={warnings.length > 0} fullWidth>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent dividers>
          <Alert severity='error' sx={{ marginBottom: 2 }}>Some parcels have multiple subsidies. Confirm to proceed with these adjustments.</Alert>
          {file && <DocumentDetailDisplay file={file} state={'Complete'} />}
          <Typography component='ul' marginTop={2}>
            {warnings.map((warning, index) => {
              return (
                <li key={index}>{warning}</li>
              )
            })}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button onClick={undo}>
            Cancel
          </Button>
          <Button onClick={() => setWarnings([])} variant='contained'>
            Confirm and proceed
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={warnings.length === 0 && Boolean(fileId)} fullWidth>
        <DialogTitle>File Uploaded</DialogTitle>
        <DialogContent dividers>
          {file && <DocumentDetailDisplay file={file} state={'Complete'} />}
          <Typography variant='h6' marginY={2}>
            Payment Adjustment Details
          </Typography>
          <DataGrid
            rows={fileRows}
            columns={columns}
            autoHeight
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#55A6FF1A'
              }
            }}
          />
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button onClick={undo}>
            Cancel
          </Button>
          <Button onClick={done} variant='contained'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <FullscreenLoading open={loading} />
    </div>
  )
}

export default PaymentAdjustments