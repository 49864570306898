import 'lodash'
import {mainMenuItems} from "../components/Common/NavBarMenu/mainMenu";

export function setUserPreference(authToken) {
    let localStorage = window.localStorage;
    localStorage.setItem('auth_token', authToken);
}

export function getUserPreference() {
    let localStorage = window.localStorage;
    return {
        authToken: localStorage.getItem('auth_token')
    }
}

export function getUserUiAbilities(isAdmin = false) {
  const token = window.localStorage.getItem("auth_token");

  let uiAbilitiesList,
    getUserFromToken,
    authorizedUiAbilities;

  if (token) {
    uiAbilitiesList = [...mainMenuItems].filter(
      (menuItem) => (menuItem.link)
    );

    getUserFromToken = parseJwt(token);
    authorizedUiAbilities = getUserFromToken['ui_abilities'] || [];

    authorizedUiAbilities = uiAbilitiesList.filter(
      (ability) => authorizedUiAbilities.includes(ability.access_ability_id)
    );

    // Organization's Partner page is not shown if use is not admin
    if (!isAdmin) {
      authorizedUiAbilities = authorizedUiAbilities.map(menuItem => {
        if (menuItem.id === "organization-main" && menuItem.nav) {
          const filteredNav = menuItem.nav.filter(navItem => navItem.id !== 1);
          return {
            ...menuItem,
            nav: filteredNav
          };
        }
        return menuItem;
      });
    }

    // Add "Main" item directly assuming it's not already included
    const mainItem = uiAbilitiesList.find(item => item.id === 'main');
    authorizedUiAbilities.unshift(mainItem);
  }

  return authorizedUiAbilities;
}

export function getUserManagedCustomers(){
  const customers = window.localStorage.getItem("customer_info");

  return JSON.parse(customers);
}

export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(Buffer.from(base64, 'base64').toString().split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const exportHelper = (response, fileName) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};