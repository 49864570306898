import React from 'react';
import {Redirect} from 'react-router-dom';
import Main from '../pages/Main';
import LocalDeliveryMain from '../pages/LocalDelivery';
import ProtectedRoute from './protectedRoute';
import ServicePointMain from '../pages/ServicePoint';
import SandboxTest from '../pages/SandboxTest';
import T4AMain from '../pages/T4A';
import PrealertStatistic from '../pages/PrealertStatistic';
import PostalOutletMain from '../pages/PostalOutlet';
import UserManagementMain from '../pages/UserManagement';
import Login from "../pages/Login";
import OrganizationMain from "../pages/Organization";
import DeliveryCapacityMain from "../pages/DeliveryCapacity";
import FaultyPackageMain from '../pages/FaultyPackage';
import FaultyPackageGVMain from "../pages/FaultyPackageGV";
import GVShippingManagement from "../pages/GVShippingManagement";
import BillingAndInvoice from "../pages/BillingAndInvoice";
import AddressManagement from "../pages/AddressManagement";
import FleetSettlement from "../pages/FleetSettlement"

export const routes = [
  {
    path:'/login',
    component: Login
  },
  {
    path: "/",
    exact: true,
    render: () => {
      return <Redirect to={'/login'}></Redirect>
    }
  },
  {
    component: ProtectedRoute,
    routes: [
      {
        path:'/main',
        component: Main
      },
      {
        path: '/local-delivery-main',
        component: LocalDeliveryMain
      },
      {
        path: '/service-point-main',
        component: ServicePointMain
      },
      {
        path: '/sandbox-test-main',
        component: SandboxTest
      },
      {
        path: '/t4a-main',
        component: T4AMain
      },
      {
        path: '/prealert-statistic',
        component: PrealertStatistic
      },
      {
        path: '/postal-outlet',
        component: PostalOutletMain
      },
      {
        path: '/user-management',
        component: UserManagementMain
      },
      {
        path: '/organization',
        component: OrganizationMain
      },
      {
        path: '/faulty-package',
        component: FaultyPackageMain
      },
      {
        path: '/faulty-package-gv',
        component: FaultyPackageGVMain
      },
      {
        path: '/delivery-capacity',
        component: DeliveryCapacityMain
      },
      {
        path: '/gv-shipping-management',
        component: GVShippingManagement
      },
      {
        path: '/billing-and-invoice',
        component: BillingAndInvoice
      },
      {
        path: '/address-management',
        component: AddressManagement
      },
      {
        path: '/fleet-settlement',
        component: FleetSettlement
      }
    ]
  }
]