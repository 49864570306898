import { useDispatch } from 'react-redux';
import { makeStyles, } from '@material-ui/core';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import { showAlertSnackbar } from "../../../reducers/uiSlice";
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { getWarehouseCapacities } from "../../../actions/deliveryCapacityActions";
import { getPartnerThresholdByWarehouse, updatePartnerThresholdByWarehouse } from "../../../actions/deliveryCapacityActions";
import FullscreenLoading from "../../FullscreenLoading";
import { top4PartnerIds } from '../DeliveryCapacityDisplay';

dayjs.extend(isoWeek);

const useStyle = makeStyles((theme) => ({
  header: {
    marginLeft: 30,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  tableArea: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    height: '90%'
  },
  table: {
    margin: '10px 0 0',
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 10.5,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.25,
      textAlign: 'left',
      color: 'rgba(185, 185, 185, 0.87)'
    },
    '& .MuiDataGrid-row': {
      height: 56,
      textAlign: 'left',
    },
    '& .partner_column_style': {
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontWeight: 550,
    },
    '& .notSet': {
      color: 'lightgray',
    },
  },
}));

const ALERT = {
  SUCCESS: "FETCH THRESHOLD DATA SUCCESS",
  FAILED: "FETCH THRESHOLD DATA FAILED",
  UPDATE_SUCCESS: "UPDATE DATA SUCCESS",
  UPDATE_FAILED:"UPDATE DATA FAILED",
};

function findChangedField(newRow, oldRow) {
  return Object.keys(newRow).find(field => newRow[field] !== oldRow[field])
}

function formatFieldValue(value, capacity) {
  return isNaN(value) ? 'N/A' : `${value}% (${(Number(value) / 100 * capacity).toLocaleString()})`
}

function DeliveryThresholdUpdate(props) {
  const { displayOpen } = props;

  const dispatch = useDispatch();
  const classes = useStyle();
  const [loading, setLoading] = useState(false);

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [year, setYear] = useState('');
  const [week, setWeek] = useState(0);
  const [capacities, setCapacities] = useState({});
  const [errPopup, setErrorPopup] = useState(true);
  const [errMsg, setErrMsg] = useState('');
  const [fullscreenLoading, setFullScreenLoading] = useState(false);
  const [confirmArguments, setConfirmArguments] = useState(null);
  const [changed, setChanged] = useState(false);

  const getWarehousePairs = useCallback(async () => {
    try {
      const res = await dispatch(getWarehouseCapacities());
      const columns = res.map(warehouse => (
        { 
          field: warehouse.name, 
          headerName: warehouse.name, 
          minWidth: 120, 
          valueFormatter: ({ value }) => formatFieldValue(value, warehouse.capacity), 
          editable: true
        }));
      setColumns([
        {
          field: 'partner_id',
          headerName: 'Partner ID',
          minWidth: 80,
          cellClassName: 'partner_column_style',
        },
        {
          field: 'partner_name',
          headerName: 'Partner',
          minWidth: 240,
          cellClassName: 'partner_column_style',
        },
      ].concat(columns));
      
      const capacities = res.reduce((accumulator, warehouse) => {
        accumulator[warehouse.name] = warehouse.capacity
        return accumulator
      }, {})
      setCapacities(capacities)
    } catch (e) {
      console.log('Fetch warehouse failed', e);
    }
  }, [dispatch])

  async function updateThreshold() {
    setFullScreenLoading(true);
    try {
      const params = {
        week: week,
        year: year,
        data: rows
      };
      await dispatch(updatePartnerThresholdByWarehouse(params));

      setChanged(false)
      dispatch(showAlertSnackbar({ message: ALERT.UPDATE_SUCCESS, type: 'success' }));
    } catch (e) {
      dispatch(showAlertSnackbar({ message: ALERT.UPDATE_FAILED+": "+e.message, type: 'error'}));
      setErrMsg(e.message);
      setErrorPopup(false);
    } finally {
      setFullScreenLoading(false);
      await fetchRowData();
    }
  }

  const fetchRowData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await dispatch(getPartnerThresholdByWarehouse(year, week));
      
      const rows = Array.from(res);
      rows.sort((a, b) => a.partner_id - b.partner_id);

      // Put top 4 partners in the beginning
      for (let partnerId of top4PartnerIds.toReversed()) {
        const index = rows.findIndex(row => row.partner_id === partnerId)
        if (index !== -1) {
          const row = rows.splice(index, 1)[0]
          rows.unshift(row)
        }
      }

      setRows(rows);

      dispatch(showAlertSnackbar({ message: ALERT.SUCCESS, type: 'success' }));
    } catch (e) {
      dispatch(showAlertSnackbar({ message: ALERT.FAILED+": "+e.message, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }, [dispatch,year,week])

  const thresHoldFooter = () => {
    return (
      <Box
        borderTop='1px solid rgb(224, 224, 224)'
        height='60px'
        display='flex'
        alignItems='center'
        justifyContent='flex-end'
        padding={2}>
        <Button
          onClick={() => {
            fetchRowData();
            setChanged(false);
          }}
          color='error'
          disabled={!changed}
          sx={{ marginRight: 1 }}>
          Cancel
        </Button>
        <Button
          onClick={updateThreshold}
          variant='contained'
          disabled={!changed}>
          Update
        </Button>
      </Box>
    );
  };

  useEffect(() => {
    if (displayOpen) {
      const date = dayjs();
      setYear(date.format('YYYY'));
      setWeek(date.isoWeek());
    }
  }, [displayOpen])

  useEffect(() => {
    if (displayOpen && year && week) {
      getWarehousePairs();
      fetchRowData();
    }
  }, [displayOpen,year,week,getWarehousePairs,fetchRowData])

  const processRowUpdate = React.useCallback(
    (newRow, oldRow) => {
      return new Promise((resolve, reject) => {
        const changedField = findChangedField(newRow, oldRow)
        const changedValue = newRow[changedField]
        if (changedValue && (changedValue === '' || !isNaN(changedValue))) {
          setConfirmArguments({ resolve, reject, newRow, oldRow });
        }
        else {
          resolve(oldRow)
        }
      })
    },
    []
  )

  const renderConfirmDialog = () => {
    if (!confirmArguments) return null;
    const { newRow, oldRow, resolve } = confirmArguments;
    const changedField = findChangedField(newRow, oldRow);
    const capacity = capacities[changedField]

    function no() {
      resolve(oldRow)
      setConfirmArguments(null)
    }
    function yes() {
      const updatedRows = rows.map((row) => {
        if (row.partner_id === newRow.partner_id) {
          row[changedField] = newRow[changedField];
          return row;
        }
        return row;
      });
      setRows(updatedRows);

      resolve(newRow)
      setConfirmArguments(null)
      setChanged(true)
    }

    return (
      <Dialog open onClose={no}>
        <DialogTitle>{newRow.partner_name}</DialogTitle>
        <DialogContent dividers>
          <b>{changedField}: </b>
          Change from <span style={{ color: 'red' }}>{formatFieldValue(oldRow[changedField], capacity)}</span> to <b style={{ color: 'green' }}>{formatFieldValue(newRow[changedField], capacity)}</b>
        </DialogContent>
        <DialogActions>
          <Button onClick={no}>
            No
          </Button>
          <Button onClick={yes}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div>
      <div className={classes.header}>
        <p style={{ color: "#577699", fontSize: "18px", fontWeight: 600, margin: "2px 0 1px" }}>Update Partner Threshold</p>
      </div>

      <div className={classes.tableArea}>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              color: "#577699",
              fontSize: 18,
              fontWeight: 600,
              marginLeft: 18,
              marginTop: 15
            }}
          >
            Week: {week}, {year}
          </div>
        </div>
      
        {renderConfirmDialog()}

        <DataGrid
          style={{ height: 'calc(100vh - 220px)' }}
          className={classes.table}
          rows={rows}
          getRowId={(row) => row.partner_id}
          columns={columns}
          loading={loading}
          pagination={false}
          components={{
            Footer: thresHoldFooter,
          }}
          disableColumnMenu
          processRowUpdate={processRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
          getCellClassName={(params) => {
            if (params.isEditable && isNaN(params.value)) {
              return 'notSet';
            }
          }}
        />
      </div>

      <Dialog maxWidth='sm' fullWidth open={!errPopup} onClose={() => setErrorPopup(false)}>
        <DialogTitle><b>Update Threshold Error</b></DialogTitle>
        <DialogContent dividers>
          <p>{"Thresholds not Updated: " + errMsg}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setErrorPopup(true) }}>Ok</Button>
        </DialogActions>
      </Dialog>

      <FullscreenLoading open={fullscreenLoading}/>
    </div>
  );
}

export default DeliveryThresholdUpdate;