import { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import PrmFrame from "../../components/PrmFrame";
import Toolbar from "@material-ui/core/Toolbar";
import { PrmMain } from "../../components/PrmMain";
import PaymentAdjustments from "../../components/PaymentAdjustments"

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f7f7f7'
  },
  content: {
    flexGrow: 1,
    width: '85vw',
    marginTop: 10
  }
}));

const FLEET_SETTLEMENT_ACCESS_ID = 15;

function FleetSettlement() {
  const classes = useStyles();
  const [menuIndex, setMenuIndex] = useState(0);
  const [open, setOpen] = useState(true);

  return (
    <div className={classes.root}>
      <PrmFrame
        ACCESS_ID={FLEET_SETTLEMENT_ACCESS_ID}
        open={open}
        setOpen={setOpen}
        menuIndex={menuIndex}
        setMenuIndex={setMenuIndex}
      />
      <PrmMain
        className={classes.content}
        open={open}>
        <Toolbar />
        <div hidden={menuIndex !== 0}>
          <PaymentAdjustments />
        </div>
      </PrmMain>
    </div>
  )
}

export default FleetSettlement