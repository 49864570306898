import { useDispatch } from 'react-redux';
import {Button, makeStyles, TextField, Typography} from '@material-ui/core';
import React, {useEffect, useState, useCallback} from 'react';
import {showAlertSnackbar} from "../../../reducers/uiSlice";
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import {updateCapacities, getWarehouseCapacities} from "../../../actions/deliveryCapacityActions";
import FullscreenLoading from "../../FullscreenLoading";

const useStyle = makeStyles((theme) => ({
  header: {
    marginLeft: 30,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  button: {
    backgroundColor: "#2a3848",
    color: " #fff",
    borderRadius: 4,
    padding: "15px 20px",
    fontSize: "12px",
    marginLeft: theme.spacing(2),
    maxHeight: "36px",
    '&:hover': {
      background: "#3f556f",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)"
    }
  },
  buttonSystem: {
    backgroundColor: "#1976d2",
    color: " #fff",
    width: 108,
    height: 42,
    padding: "6px 16px",
    borderRadius: 4,
    boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
    fontSize: "12px",
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  footerContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: theme.spacing(6),
    paddingRight: theme.spacing(2),
  },
  box: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "12px 0 12px 30px",
    padding: "24px"
  },
  table: {
    height: 560,
    margin: '10px 0 0',
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 12,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.28,
      textAlign: 'left',
      color: 'rgba(185, 185, 185, 0.87)'
    },
    '& .MuiDataGrid-row': {
      height: 56,
      textAlign: 'left',
    },
    '& .green-text': {
      color: 'green'
    },
    '& .yellow-text': {
      color: 'orange'
    },
    '& .red-text': {
      color: 'red'
    }
  },
}));

const ALERT = {
  SEARCH_SUCCESS  : "FETCH DATA SUCCESS",
  SEARCH_FAILED   : "FETCH DATA FAILED",
  UPDATE_SUCCESS  : "UPDATE CAPACITY SUCCESS",
  UPDATE_FAILED   : "UPDATE CAPACITY FAILED",
};

function DeliveryCapacityUpdate(props) {
  const {updateOpen} = props;

  const dispatch = useDispatch();
  const classes = useStyle();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [week, setWeek] = useState(0);

  const mergeLists = (listA, listB) => {
    return listA.map(itemA => {
      const matchingItemB = listB.find(itemB => itemB.warehouse_id === itemA.warehouse_id);
      if (matchingItemB) {
        return {
          warehouse_id: itemA.warehouse_id,
          name: itemA.name,
          curr_capacity: itemA.capacity,
          next_capacity: matchingItemB.capacity
        };
      } else {
        return {
          warehouse_id: itemA.warehouse_id,
          name: itemA.name,
          curr_capacity: itemA.capacity,
          next_capacity: 0
        };
      }
    });
  };

  const updateListById = (id, updatedData) => {
    setList((prevList) =>
      prevList.map((obj) => (obj.warehouse_id === id ? { ...obj, ...updatedData } : obj))
    );
  };

  const handleChangeCapacity = (e, id, propName) => {
    const value = e.target.value;
    updateListById(id, { [propName]: +value });
  };

  const handleUpdateCapacities = (id) => {
    const { warehouse_id, curr_capacity, next_capacity } = list.find(warehouse => warehouse.warehouse_id === id);

    const updateParams = async (week, capacity) => {
      const params = {
        id: warehouse_id,
        week: week,
        capacity: capacity
      };
      await dispatch(updateCapacities(params));
    };

    setLoading(true);
    try {
      updateParams(week, curr_capacity);
      updateParams(week + 1, next_capacity);
      dispatch(showAlertSnackbar({message: ALERT.UPDATE_SUCCESS, type: 'success'}));
    } catch (e) {
      dispatch(showAlertSnackbar({message: ALERT.UPDATE_FAILED, type: 'error'}));
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = useCallback(async (week) => {
    setLoading(true);
    try {
      const curr = await dispatch(getWarehouseCapacities());
      const next = await dispatch(getWarehouseCapacities('', week + 1));
      setList(mergeLists(curr, next));
      dispatch(showAlertSnackbar({message: ALERT.SEARCH_SUCCESS, type: 'success'}));
    } catch (e) {
      dispatch(showAlertSnackbar({message: ALERT.SEARCH_FAILED, type: 'error'}));
    } finally {
      setLoading(false);
    }
  }, [dispatch])

  const warehouseBox = (warehouse) => {
    const { warehouse_id, name, curr_capacity, next_capacity } = warehouse;

    return (
      <div className={classes.box}>
        <Typography variant='h5'>
          {name}
        </Typography>
        <Typography variant='body2' color='textSecondary'>
          Please enter max capacity for the current and next week
        </Typography>

        <div style={{ display: 'flex', marginTop: '24px' }}>
          <TextField
            id={`${name}-current-week`}
            label={'Current Week'}
            variant="outlined"
            value={curr_capacity}
            onChange={(e) => handleChangeCapacity(e, warehouse_id, 'curr_capacity')}
          />
          <TextField
            style={{ marginLeft: '24px' }}
            id={`${name}-next-week`}
            label={'Next Week'}
            variant="outlined"
            value={next_capacity}
            onChange={(e) => handleChangeCapacity(e, warehouse_id, 'next_capacity')}
          />
        </div>
        <div style={{ marginTop: '24px' }}>
          <Button
            id={`update-${name}`}
            className={classes.buttonSystem}
            style={{cursor: 'pointer'}}
            onClick={(e)=>handleUpdateCapacities(warehouse_id)}
          >
            Update
          </Button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (updateOpen) {
      dayjs.extend(isoWeek);
      const date = dayjs();
      setWeek(date.isoWeek());
      handleSearch(date.isoWeek());
    }
  }, [updateOpen, handleSearch])

  return (
    <div>
      <div className={classes.header}>
        <p
          style={{
            color: "#577699",
            fontSize: "18px",
            fontWeight: 600,
            margin: "2px 0 1px"
        }}>
          Update Delivery Maximum Capacity
        </p>
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {list && list.length > 0 && (
          list.map((warehouse, index) => (
            <div key={index}>
              {warehouseBox(warehouse)}
            </div>
          ))
        )}
      </div>
      <FullscreenLoading open={loading}/>
    </div>
  );
}

export default DeliveryCapacityUpdate;